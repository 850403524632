<template>
    <div @keyup.esc="joinOurs" tabindex="-1">
        <Header :activeIndex="2"></Header>
        <!-- banner -->
        <div class="banner">
            <img class="bigImg" src="@/assets/images/officialWebsite/recruit-banner.png" alt="天游">
            <img class="smaImg" src="@/assets/images/officialWebsite/recruit-minbanner.png" alt="天游">
        </div>
        <div class="recruitInfo_con" v-for="(item,i) in recruitDate" :key="i">
            <Recruit :class="{firstRec: i == 0}" :recruitDate="item" :floatShow="floatShow" @update="changeStatus"></Recruit>
        </div>
        <div class="recruit_float" v-if="floatShow">
            <div class="floatContent">
                <div class="msgContent">
                    <i @click="joinOurs">X</i>
                    <p>未来可期，加入我们，再续传奇！</p>
                    <p>联系电话：13918275366</p>
                    <p>邮箱地址：xc.liu@t2cn.com</p>
                    <p>联系地址：上海市桂平路391号漕河泾新国际商务中心A幢19楼</p>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    export default {
        name: "RecruitInfo",
        data () {
            return {
                floatShow: false,
                recruitDate: [
                    {
                        professionName: 'Web前端开发工程师',
                        bgWidth: {
                            'width': '248px'
                          },
                        updateTime: '2021-05-20 13:14:00',
                        city: '上海',
                        jobRespons: [
                            '1. 本科及以上学历，有过前端完整项目开发并有作品展示（必须)；',
                            '2. 精通HTML5,CSS3,JavaScript，熟悉前端各大主流框架（AngularJS、Bootstrap、jQuery、Vue.js、React.js）的使用；',
                            '3. 能使用Ajax执行异步服务器请求,能够使用bootstrap实现响应式布局并能够定bootstrap样式（必须)；',
                            '4. 研究并实施 Web 开发相关的前沿技术。'
                        ],
                        jobRequire: [
                            '1. 计算机类相关专业，全日制本科及以上学历；',
                            '2. 三年以上相关开发经验，至少有主流框架成功上线的两个项目；',
                            '3. 具备良好团队协作精神，学习能力强，工作积极主动，能承受一定的工作压力；',
                            '4. 性格开朗,善于沟通,思维缜密,逻辑清晰。'
                        ]
                    },
                    {
                        professionName: 'Golang开发工程师',
                        bgWidth: {
                          'width': '240px'
                        },
                        updateTime: '2021-05-20 13:14:00',
                        city: '上海',
                        jobRespons: [
                            '1. 采用Go语言(Golang)开发高可用、 高并发的后端框架、组件、服务；',
                            '2. 参与后台系统设计；',
                            '3. 参与系统性能优化，难点攻关，保证系统的效率和可靠性以及代码质量和可维护性。'
                        ],
                        jobRequire: [
                            '1. 两年以上go后端开发经验，至少有1到2个完整项目开发，有使用iris开发经验优先；',
                            '2. 扎实的golang基础，懂得代码调优及性能优化方案，简单的go项目目录架构经验；',
                            '3. 熟悉docker、微服务相关技术方案；',
                            '4. 熟悉MySQL数据库，熟悉gorm，以及常用的NoSQL，如Redis等；',
                            '5. 熟悉常用的Linux命令，简单shell脚本编写；',
                            '6. 有良好的沟通及团队协作能力；',
                            '7. 优秀的问题分析能力和敏捷的思考能力.对于新技术有高度的热情和不断学习的能力。'
                        ]
                    },
                    {
                        professionName: 'Android多媒体SDK研发工程师',
                        bgWidth: {
                          'width': '373px'
                        },
                        updateTime: '2021-05-20 13:14:00',
                        city: '上海',
                        jobRespons: [
                            '1. 负责公司产品 Android 客户端 SDK 的研发，编写高质量的代码；',
                            '2. 参与应用架构设计、开发、性能优化等工作；',
                            '3. 持续不断的改进用户体验，负责相关新技术新架构的实现；',
                            '4. 根据团队的整体目标和规划，进行相关的研发工作。'
                        ],
                        jobRequire: [
                            '1. 有强烈的求知欲和进取心；',
                            '2. 一年以上 Android 开发经验，能独立开发 Android App 者优先；',
                            '3. 熟练掌握 Java 语言，熟练掌握多线程、常用数据结构、操作系统知识，熟悉 JNI 以及 NDK 相关的技能，熟悉 C/C++；',
                            '4. 熟悉 Android 架构，特别是多媒体架构；',
                            '5. 熟悉音视频、特效相关多媒体客户端开发，有实际项目开发经验优先；',
                            '6. 具有较好的代码风格，有较强的独立工作能力，熟悉 Android 各版本的适配与系统兼容。'
                        ]
                    }
                ]
            }
        },
        methods: {
            changeStatus (status) {
                this.floatShow = status
            },
            joinOurs () {
                this.floatShow = false
            }
        }
    }
</script>

<style scoped lang="scss">
    /*屏幕大于1200px*/
    @media screen and (min-width: 1200px) {
        .banner {
            .bigImg {
                width: 1200px;
                height: 480px;
                position: absolute;
                left: 50%;
                transform: translate(-600px);
            }
        }
        .recruitInfo_con {
            width: 1200px;
            margin: 0 auto;
        }
    }
    /*屏幕小于1200px大于800px*/
    @media screen and (max-width: 1200px) and (min-width: 800px) {
        .banner {
            .bigImg {
                width: 100%;
                height: 480px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .recruitInfo_con {
            width: 100%;
        }
    }
    /*屏幕小于800px大于600px*/
    @media screen and (max-width: 800px) and (min-width: 600px){
        .banner {
            height: 300px !important;
            .bigImg {
                display: none;
            }
            .smaImg {
                display: block!important;
                width: 600px;
                height: 300px;
                position: absolute;
                left: 50%;
                transform: translate(-300px);
            }
        }
        .recruitInfo_con {
            width: 100%;
        }
        .floatContent {
            width: 80% !important;
        }
    }
    /*屏幕小于600px*/
    @media screen and (max-width: 600px) {
        .banner {
            height: 300px !important;
            .bigImg {
                display: none;
            }
            .smaImg {
                display: block!important;
                width: 100%;
                height: 300px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .recruitInfo_con {
            width: 100%;
        }
        .floatContent {
            width: 80% !important;
        }
    }

    .banner {
        width: 100%;
        background: #2F333D;
        height: 480px;
        margin-bottom: 9px;
        position: relative;
        .smaImg {
            display: none;
        }
    }
    .recruit_float {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        position: fixed;
        top: 0;
        z-index: 101;
        display: flex;
        justify-content: center;
        align-items: center;
        .floatContent {
            width: 560px;
            min-height: 240px;
            background: #ffffff;
            padding: 30px;
            position: relative;
            .msgContent{
                width: 100%;
                height: 100%;
                color: #333333;
                text-align: left;
                font-size: 20px;
                margin: 0px 13px;
                i {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    color: #666;
                    cursor: pointer;
                    font-style: normal;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
                p {margin: 0;padding: 0;}
                p:nth-of-type(1) {
                    width: 100%;
                }
                p:nth-of-type(2), p:nth-of-type(3), p:nth-of-type(4){
                    width: 100%;
                    border-left: 8px solid #D69D4D;
                    padding-left: 12px;
                    font-size: 16px;
                    margin-top: 20px;
                }
            }
        }
    }

</style>
